/*csslint important:false */
.page-login {
  align-items: center;
  background: url('./login-bg.jpg') no-repeat top center;
  background-size: cover;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.password-reset-form {
  margin-top: -4rem;
}

.password-reset-form {
  background: #000;
  background: rgba(63, 63, 63, .6);
  border: 1px solid #1a1a1a;
  padding: 2rem;
}

.password-reset-form form {
  margin-top: 2rem;
}

.password-reset-form p {
  color: #999;
  margin-top: 2rem;
}

.password-reset-form .password-rules {
  color: #999;
  margin: 2rem 0;
  text-align: left;
}

.password-reset-form .input {
  margin: 0;
}

.password-reset-form input {
  background: #eee !important;
  border-radius: 0 !important;
  width: 100%;
}

.password-reset-form button {
  background: #00cc00 !important;
  border-radius: 0 !important;
  color: #000 !important;
  width: 100%;
}
