.page-home {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-height: 100vh;
}

main {
  flex: 1;
}

.page-home main > div {
  padding-top: 2rem !important;
}
