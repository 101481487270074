html,
body {
  min-height: 100%;
  height: auto;
}

body {
  /* background: linear-gradient(174deg, #1b1c1d 300px, #fff 600px); */
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  padding-bottom: 40px;
}

/* navbar gravatar */
.navbar-gravatar {
  display: inline-block !important;
  height: 30px;
  margin-left: 5px;
}

/* list */
.list-description {
  color: #999;
  font-size: .9em;
}

/* form */
.ui.multiple.selection .label {
  margin-right: 8px !important;
  margin-top: 3px !important;
}

/* revision sidebar */
.sidebar-revision {
  background: #fff;
}

/* comments sidebar */
.sidebar-comments {
  background: #fff;
}

/* footer */
.app-footer {
  align-items: center;
  background: #cecece;
  bottom: 0;
  color: #000;
  margin-top: 2rem;
  padding:  4px 2rem;
  position: fixed;
  width: 100%;
}

/* tour */
.joyride-tooltip__main {
  font-size: .9em !important;
}

.ui.menu {
  border-radius: 0;
}

.actions-menu i {
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0 30px !important;
}

.actions-menu > .icon-comments-wrapper {
  margin-left: 30px;
  position: relative;
}

.actions-menu i:first-child {
  margin-left: 0 !important;
}

.actions-menu i:last-child {
  margin-right: 0 !important;
}

footer {
  display: flex;
  justify-content: space-between;
}

.page-home {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-height: 100vh;
}

main {
  flex: 1 1;
}

.page-home main > div {
  padding-top: 2rem !important;
}



.sketch {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.sketch-toolbar {
  margin-bottom: 16px;
}

.sketch-stroke-trigger {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 21px;
}

.sketch-stroke-trigger > span {
  background: #fff;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.page-mockup-revision {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-mockup-revision .pushable {
  flex: 1 1;
  margin-top: 0rem;
}

.page-mockup-revision .iframe-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  padding-top: 2rem;
}

.page-mockup-revision iframe {
  min-height: 100%;
  max-width: 100%;
  width: 100%;
}

.page-mockup-revision {
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.sidebar-container,
.sidebar-container > .pusher,
.sidebar-container > .pusher > div,
.sidebar-container > .pusher > div > main,
.sidebar-container > .pusher > div > main > .iframe-container {
  min-height: 100vh !important;
}

.page-mockup-revision iframe {
  height: 100vh !important;
}

.page-mockup-revision .ui.menu {
  margin-bottom: 0;
}

.page-mockup-revision .app-footer {
  margin-top: 0
}

.page-mockup-revision .mockup-unavailable {
  display: flex;
  flex: 1 1;
}

.page-mockup-revision .mockup-unavailable > div {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.page-mockup-raw-revision {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-mockup-raw-revision .iframe-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
}

/*csslint important:false */
.page-login {
  align-items: center;
  background: url(/static/media/login-bg.41338272.jpg) no-repeat top center;
  background-size: cover;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.login-form {
  margin-top: -4rem;
}


.login-form {
  background: #000;
  background: rgba(63, 63, 63, .6);
  border: 1px solid #1a1a1a;
  padding: 2rem;
}

.login-form form {
  margin-top: 2rem;
}

.login-form p {
  color: #999;
  margin-top: 2rem;
}

.login-form p.forgot-password {
  margin: 2rem 0 0;
}

.login-form .input {
  margin: 0;
}

.login-form input {
  background: #eee !important;
  border-radius: 0 !important;
  width: 100%;
}

.login-form button {
  background: #00cc00 !important;
  border-radius: 0 !important;
  color: #000 !important;
  width: 100%;
}

/*csslint important:false */
.page-login {
  align-items: center;
  background: url(/static/media/login-bg.41338272.jpg) no-repeat top center;
  background-size: cover;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.password-reset-form {
  margin-top: -4rem;
}

.password-reset-form {
  background: #000;
  background: rgba(63, 63, 63, .6);
  border: 1px solid #1a1a1a;
  padding: 2rem;
}

.password-reset-form form {
  margin-top: 2rem;
}

.password-reset-form p {
  color: #999;
  margin-top: 2rem;
}

.password-reset-form .password-rules {
  color: #999;
  margin: 2rem 0;
  text-align: left;
}

.password-reset-form .input {
  margin: 0;
}

.password-reset-form input {
  background: #eee !important;
  border-radius: 0 !important;
  width: 100%;
}

.password-reset-form button {
  background: #00cc00 !important;
  border-radius: 0 !important;
  color: #000 !important;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.underline {
  background: yellow;
  display: inline-block;
}

